import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { userStore } from "../store/userStore";

import Page404 from "../pages/404/404.page";
import LoginPage from "../pages/login/login.page";
import {Roles, CheckRoleContains} from "../store/rolesStore";

import AdminLayout from "../layout/admin.layout.component";
import Preloader from "./general/preloader/preloader.component";

// ADMIN PAGES
const IndexPage = lazy(() => import("../pages/admin/index.page"));
const MenuPage = lazy(() => import("../pages/admin/menu/menu.page"));
const AddMenuPage = lazy(() => import("../pages/admin/menu/add.menu.page"));
const EditMenuPage = lazy(() => import("../pages/admin/menu/edit.menu.page"));
const ProfilePage = lazy(() => import("../pages/admin/profile/profile.page"));
const UsersPage = lazy(() => import("../pages/admin/users/users.page"));
const AddUserPage = lazy(() => import("../pages/admin/users/add.user.page"));
const EditUserPage = lazy(() => import("../pages/admin/users/edit.user.page"));
const SchoolsPage = lazy(() => import("../pages/admin/schools/schools.page"));
const AddSchoolsPage = lazy(() => import("../pages/admin/schools/add.schools.page"));
const EditSchoolsPage = lazy(() => import("../pages/admin/schools/edit.schools.page"));
const SchoolPage = lazy(() => import("../pages/admin/school/school.page"));
const SchedulesPage = lazy(() => import("../pages/admin/schedules/schedules.page"));
const RequestsPage = lazy(() => import("../pages/admin/requests/requests.page"));

const RoutesList = () => {
    const CheckAccess = () => {
        return userStore.value && userStore.value.roleID && userStore.value.roleID > 0;
    };

    const GetRoleRoutes = () => {
        return (<>
            {
                <Route path='users'>
                    {
                        CheckRoleContains([Roles.SUPERADMIN, Roles.ADMIN])
                        &&
                        <Route index element={<UsersPage />} />
                    }
                    {
                        CheckRoleContains([Roles.SUPERADMIN, Roles.ADMIN, Roles.DIRECTOR])
                        &&
                        <Route path='edit/:id/:school?/:roles?' element={<EditUserPage />} />
                    }
                    {
                        CheckRoleContains([Roles.SUPERADMIN, Roles.ADMIN, Roles.DIRECTOR])
                        &&
                        <Route path='new/:school?/:roles?' element={<AddUserPage />} />
                    }
                </Route>
            }
            {
                CheckRoleContains([Roles.SUPERADMIN, Roles.ADMIN])
                &&
                <Route path='schools'>
                    <Route index element={<SchoolsPage />} />
                    <Route path='edit/:id' element={<EditSchoolsPage />} />
                    <Route path='new' element={<AddSchoolsPage />} />
                </Route>
            }
            {
                CheckRoleContains([Roles.DIRECTOR, Roles.TEACHER, Roles.TEACHER_OFFICER, Roles.OFFICER, Roles.CADET])
                &&
                <Route path='school'>
                    <Route index element={<SchoolPage />} />
                </Route>
            }
            {
                CheckRoleContains([Roles.ALL])
                &&
                <Route path='schedules'>
                    <Route index element={<SchedulesPage />} />
                </Route>
            }
            {
                CheckRoleContains([Roles.ALL])
                &&
                <Route path='requests'>
                    <Route index element={<RequestsPage />} />
                </Route>
            }
        </>);
    };

    const routes = (
        <Suspense fallback={<Preloader loading={true} />}>
            <Routes>
                <Route path='/admin' element={<AdminLayout />}>
                    <Route index element={<IndexPage />} />
                    {
                        GetRoleRoutes()
                    }
                </Route>
                <Route path='/profile' exact={true} element={<AdminLayout />}>
                    <Route index element={<ProfilePage />} />
                </Route>
                <Route path='/login' exact={true} element={<Navigate to='/admin/' />} />
                <Route path='/' exact={true} element={<Navigate to='/admin/' />} />
                <Route path='*' element={<Page404 />} />
            </Routes>
        </Suspense>
    );

    if (CheckAccess()) {
        return routes;
    }

    return (
        <Routes>
            <Route path='/login' exact={true} element={<LoginPage />} />
            <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
    );
};

export default RoutesList;
