import {userStore} from "./userStore";

export const Roles = {
    ALL: 0,
    SUPERADMIN: 1,
    ADMIN: 2,
    DIRECTOR: 3,
    TEACHER: 4,
    OFFICER: 5,
    TEACHER_OFFICER: 6,
};

export const GetRoleTitle = (roleID) => {
    switch (parseInt(roleID)) {
        case Roles.SUPERADMIN: return "Главный администратор";
        case Roles.ADMIN: return "Администратор";
        case Roles.DIRECTOR: return "Директор";
        case Roles.TEACHER: return "Преподаватель";
        case Roles.OFFICER: return "Офицер воспитатель";
        case Roles.TEACHER_OFFICER: return "Преподаватель и Офицер воспитатель";
    }
};

export const CheckRoleContains = (roles = []) => {
    return roles.includes(0) || roles.includes(userStore.value.roleID);
};