import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/admin/header/header.component";
import Menu from "../components/admin/menu/menu.component";
import {Roles} from "../store/rolesStore";

import { AdminMenuIcons } from "../components/svgs.js";
import "../styles/vendor/variables.css";
import "../styles/styles.scss";

const AdminLayout = () => {
    const [burgerOpened, setBurgerOpened] = React.useState(false);
    const menu = [
        {
            title: "Пользователи",
            icon: AdminMenuIcons.users,
            link: "/admin/users",
            roles: [Roles.SUPERADMIN, Roles.ADMIN]
        },
        {
            title: "Школы",
            icon: AdminMenuIcons.schools,
            link: "/admin/schools",
            roles: [Roles.SUPERADMIN, Roles.ADMIN]
        },
        // {
        //     title: "Файлы",
        //     icon: AdminMenuIcons.media,
        //     link: "/admin/mediaFiles",
        //     roles: [Roles.SUPERADMIN, Roles.ADMIN]
        // },
        {
            title: "separator",
            separator: true,
            roles: [Roles.SUPERADMIN, Roles.ADMIN]
        },
        {
            title: "Моя школа",
            icon: AdminMenuIcons.school,
            link: "/admin/school",
            roles: [Roles.DIRECTOR, Roles.OFFICER, Roles.TEACHER, Roles.TEACHER_OFFICER, Roles.CADET]
        },
        {
            title: "Расписания экзаменов",
            icon: AdminMenuIcons.schedule,
            link: "/admin/schedules",
            roles: [Roles.ALL]
        },
        {
            title: "Заявки на экзамены",
            icon: AdminMenuIcons.applications,
            link: "/admin/requests",
            roles: [Roles.ALL]
        },
    ];

    const handleBurgerMenu = () => {
        setBurgerOpened(!burgerOpened);
    };

    return (
        <div className='app'>
            <Menu menu={menu} burgerOpened={burgerOpened} setBurgerOpened={handleBurgerMenu} />
            <main className='app__main'>
                <Header extraClass={"app__header"} handleBurger={handleBurgerMenu} />
                <div className='app__content'>
                    <Outlet />
                </div>
            </main>
        </div>
    );
};

export default AdminLayout;
